import * as React from "react";
import { css } from "@emotion/react";
import { FieldError, RegisterOptions } from "react-hook-form";
import { ILabelProps, Label } from "./Label";
import { IBorderStyle, Select } from "./Select";
import { InputError } from "./InputError";
import { Notice } from "./Notice";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import {
  IBorderColorStyle,
  IFontSizeStyle,
} from "main/javascripts/styles/base/formStyle";

export interface IProps {
  namespace: string;
  label?: ILabelProps;
  select: React.AllHTMLAttributes<HTMLSelectElement>;
  error?: FieldError;
  notice?: string;
  validate?: RegisterOptions;
  defaultValue?: string;
  borderStyleKey?: keyof IBorderStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  fontSizeStyleKey?: keyof IFontSizeStyle;
  styles?: any;
}

export const SelectBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    namespace,
    label,
    select,
    error,
    notice,
    styles = {},
    validate,
    defaultValue,
    borderStyleKey,
    fontSizeStyleKey,
    borderColorStyleKey,
  } = props;
  const { exBlockStyle } = styles;
  return (
    <div css={[blockStyle, exBlockStyle]}>
      {label && <Label {...label} />}
      {notice && <Notice>{notice}</Notice>}
      <Select
        {...select}
        validate={validate}
        defaultValue={defaultValue}
        borderStyleKey={borderStyleKey}
        borderColorStyleKey={borderColorStyleKey}
        fontSizeStyleKey={fontSizeStyleKey}
      >
        {select.children}
      </Select>
      {error && (
        <InputError
          namespace={namespace}
          errorMessage={error.message}
          label={select.name}
        />
      )}
    </div>
  );
};

const blockStyle = css`
  width: 100%;
  padding: calc(${space.atom} - (${lineHeight.body1} - ${fontSize.body}) / 2)
    ${space.atom} ${space.atom2x};
  box-sizing: border-box;
`;
